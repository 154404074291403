<template>
  <VTermPopup>
    <div class="c-term-popup__wrapper">
      <div class="c-term-popup__col">
        <img class="c-term-popup__image" src="https://res.cloudinary.com/forlagshuset/image/upload/v1542792302/VB%20Polska/_MG_8206_40_fxft6ugwuk.jpg">
      </div>
      <div class="c-term-popup__col c-term-popup__col--content">
        <h2 class="c-term-popup__heading" id="termPopupTitle">
          Mydrebilde
        </h2>

        <p class="c-term-popup__content" id="termPopupDesc">
          Elevbok s. 43-44
        </p>
      </div>
    </div>
  </VTermPopup>
</template>

<script>
import { FHTermPopup as VTermPopup } from '@forlagshuset/v-term-popup'

export default {
  name: 'TermPopup',

  components: {
    VTermPopup
  }
}
</script>
